import React from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '@nextui-org/react'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useToastMessage } from 'hooks/useToastMessage'
import { resetUserPassword } from 'services/userService'
import { passwordResetSchema } from 'validations/passwordResetSchema'
import { FormInput } from 'components/partner/claim-forms/form-generics/FormInputField'

export const PasswordReset = () => {
  const { showToast } = useToastMessage()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(passwordResetSchema),
  })

  const onSubmit = async data => {
    try {
      const { currentPassword, newPassword, confirmNewPassword } = data
      const response = await resetUserPassword(
        currentPassword,
        newPassword,
        confirmNewPassword,
      )
      if (response?.status === 200) {
        showToast('Password reset successfully!', 'success')
        reset()
        navigate('/')
      } else {
        showToast('Failed to reset password. Try again.', 'error')
        reset()
      }
    } catch (error) {
      showToast('Failed to reset password. Try again.', 'error')
      reset()
    }
  }

  return (
    <div className="flex mt-8 p-4 justify-center bg-gray-50">
      <div className="bg-white p-8 rounded-xl shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-semibold text-center text-gray-800 mb-6">
          Reset Password
        </h2>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <FormInput
            label="Current Password"
            name="currentPassword"
            type="password"
            register={register}
            errors={errors}
          />
          <FormInput
            label="New Password"
            name="newPassword"
            type="password"
            register={register}
            errors={errors}
          />
          <FormInput
            label="Confirm New Password"
            name="confirmNewPassword"
            type="password"
            register={register}
            errors={errors}
          />
          <div className="flex justify-end">
            <Button
              type="submit"
              className="bg-gray-700 hover:bg-gray-950 text-white px-4 py-2 rounded-md"
            >
              Reset Password
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
