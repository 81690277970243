export const columnsToDisplay = [
  'id',
  'site_booked',
  'title',
  'type',
  'uploaded_by',
  'created_at',
  'url',
  'actions',
]

export const filterSubmissionOptions = [
  {
    value: 'daily',
    label: 'Daily',
  },
  {
    value: 'claims',
    label: 'Claims',
  },
  {
    value: 'end_of_month',
    label: 'End of Month',
  },
]
export const typeSubmissionOptions = [
  {
    value: 'daily',
    label: 'Daily',
  },
  {
    value: 'end_of_month',
    label: 'End of Month',
  },
]

export const uploadReportModalActions = onReportTabPress => [
  { label: 'Close', color: 'danger', variant: 'light' },
  {
    label: 'Upload',
    color: 'default',
    variant: 'solid',
    button_background: 'bg-gold-100',
    onPress: onReportTabPress,
    disabled: true,
  },
]
