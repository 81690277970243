import axiosInstance from './axiosConfig'
import { PartnerEndPoints } from './api-constants'

export const getFormTemplates = async (page = 1, q = '') => {
  return await axiosInstance.get(
    PartnerEndPoints.getPartnerFormTemplates(page, q),
  )
}

export const verifyClaimTemplate = async url_suffix => {
  return await axiosInstance.get(
    PartnerEndPoints.verifyClaimTemplate(url_suffix),
  )
}

export const submitPartnerClaimForm = async formData => {
  return await axiosInstance.post(
    PartnerEndPoints.submitPartnerClaimForm,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )
}

export const getAllClaimSubmissions = async (
  page = 1,
  q = '',
  filter = {},
  date_start = '',
  date_end = '',
) => {
  return await axiosInstance.get(
    PartnerEndPoints.getAllClaimSubmissions(
      page,
      q,
      filter,
      date_start,
      date_end,
    ),
  )
}

export const getAllPartnerCustomers = async (
  page = 1,
  q = '',
  f = {},
  date_start = '',
  date_end = '',
) => {
  return await axiosInstance.get(
    PartnerEndPoints.getAllPartnerCustomers(page, q, f, date_start, date_end),
  )
}

export const getClaimSubmissionOfClient = async claim_form_id => {
  return await axiosInstance.get(
    PartnerEndPoints.getClaimFormOfClient(claim_form_id),
  )
}

export const getClaimDataByIds = async claim_data_ids => {
  return await axiosInstance.post(PartnerEndPoints.getClaimDataByIds, {
    ids: claim_data_ids,
  })
}

export const getClientDataByIds = async client_data_ids => {
  return await axiosInstance.post(PartnerEndPoints.getClientsById, {
    ids: client_data_ids,
  })
}

export const requestAllClaimSubmissionDownload = async filter => {
  return await axiosInstance.post(PartnerEndPoints.createAllClaimsDownload, {
    filter: filter,
  })
}

export const requestAllClientDownload = async filter => {
  return await axiosInstance.post(PartnerEndPoints.createAllClientDownload, {
    filter: filter,
  })
}

export const uploadReport = async (csvFile, type) => {
  try {
    const formData = new FormData()
    formData.append('file', csvFile)
    formData.append('type', type)

    return await axiosInstance.post(PartnerEndPoints.uploadReport, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  } catch (error) {
    console.error('Error uploading report:', error)
    throw error
  }
}

export const getAllReports = async (
  page = 1,
  q = '',
  f = {},
  date_start = '',
  date_end = '',
) => {
  return await axiosInstance.get(
    PartnerEndPoints.getAllDownloadReports(page, q, f, date_start, date_end),
  )
}

export const getAllMonthlyReports = async (
  page = 1,
  q = '',
  f = {},
  date_start = '',
  date_end = '',
) => {
  return await axiosInstance.get(
    PartnerEndPoints.getAllMonthlyReports(page, q, f, date_start, date_end),
  )
}

export const updatePartnerOfferDetails = async offerText => {
  return await axiosInstance.patch(PartnerEndPoints.updatePartnerOfferDetails, {
    offer: offerText,
  })
}

export const generateBatchPresignedUrls = async files => {
  return await axiosInstance.post(PartnerEndPoints.generateBatchPresignedUrls, {
    files,
  })
}
