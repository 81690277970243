import axiosInstance from './axiosConfig'
import { AuthEndPoints } from './api-constants'

export const authenticateUser = async (username, password) => {
  return await axiosInstance.post(AuthEndPoints.login, {
    username,
    password,
  })
}

export const generateQRCode = async token => {
  return await axiosInstance.get(AuthEndPoints.generateQRCode, {
    headers: {
      Authorization: `Token ${token}`,
    },
  })
}

export const verifyOTP = async (otp, token) => {
  return await axiosInstance.post(
    AuthEndPoints.verifyOTP,
    { otp },
    {
      headers: {
        Authorization: `Token ${token}`,
      },
    },
  )
}

export const resetUserPassword = async (
  current_password,
  new_password,
  confirm_password,
) => {
  return await axiosInstance.post(AuthEndPoints.resetUserPassword, {
    current_password,
    new_password,
    confirm_password,
  })
}

export const getLoggedinUserDetails = async () => {
  return await axiosInstance.get(AuthEndPoints.getUserDetails)
}
