import './tailwind.css'
import App from './App'
import React from 'react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom/client'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { store, persistor } from './store'
import reportWebVitals from './reportWebVitals'
import { NextUIProvider } from '@nextui-org/react'
import { BrowserRouter as Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { getLoggedinUserDetails } from 'services/userService'
import { disableConsoleLogs } from 'helpers/disableConsoleLogs'
import { refreshCurrentUser } from 'redux-slices/currentUserSlice'

const root = ReactDOM.createRoot(document.getElementById('root'))
const isProd = process.env.REACT_APP_CLIENT_ENV === 'production'

// Disable logs
if (isProd) {
  disableConsoleLogs()
}

const initializeUser = async () => {
  const token = localStorage.getItem('accessToken')
  if (token) {
    try {
      const response = await getLoggedinUserDetails()
      store.dispatch(refreshCurrentUser(response.data))
    } catch (error) {
      console.error('Error fetching user details:', error)
    }
  }
}

initializeUser().then(() => {
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <NextUIProvider>
              <App />
            </NextUIProvider>
          </Router>
        </PersistGate>
      </Provider>
    </React.StrictMode>,
  )
})

reportWebVitals()
