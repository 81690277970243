export const columnsToDisplay = [
  'id',
  'site_booked',
  'title',
  'type',
  'url',
  'status',
  'created_at',
  'actions',
]

export const filterSubmissionOptions = [
  {
    value: 'customers',
    label: 'Customers',
  },
  {
    value: 'claims',
    label: 'Claims',
  },
]
