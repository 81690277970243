import { useFilters } from 'hooks/useFilters'
import { usePagination } from 'hooks/usePagination'
import { useToastMessage } from 'hooks/useToastMessage'
import React, { useCallback, useMemo, useState } from 'react'
import { getAllMonthlyReports } from 'services/partnerService'
import { InformationModal } from 'components/generics/info-modal'
import { handleUploadReport } from './monthly-report-tab-helpers'
import { formatDataDateByKey, formatDataForTable } from 'helpers'
import { Spinner, Tooltip, Button, Input } from '@nextui-org/react'
import { FiltersSideDrawer } from 'components/generics/filters-drawer'
import { usePartnersListFromRedux } from 'hooks/usePartnersListFromRedux'
import { CloudArrowDown, Upload, Article, Funnel } from '@phosphor-icons/react'
import { FilterDropdown } from 'components/generics/filter-dropdown/FilterDropDown'
import { PaginationComponent } from 'components/generics/pagination/PaginationComponent'
import { DefaultTableComponent } from 'components/generics/default-table/DefaultTableComponent'
import {
  columnsToDisplay,
  filterSubmissionOptions,
  typeSubmissionOptions,
  uploadReportModalActions,
} from './monthly-report-tab-constant'

export const MonthlyReportsTab = () => {
  const { showToast } = useToastMessage()
  const [csvFile, setCsvFile] = useState(null)
  const partnerList = usePartnersListFromRedux()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [selectedType, setSelectedType] = useState(null)
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false)
  const [filters, setFilters] = useState({ filter: '', partner: '' })

  const {
    selectedFilter,
    dateRange,
    handleFilterChange,
    handleDateRangeSubmit,
    resetFilters,
  } = useFilters({
    enableSearch: false,
    enableFilter: true,
    enableDateRange: true,
  })

  const {
    data,
    totalPages,
    currentPage,
    isLoading,
    handlePageChange,
    handleFilter,
    updateFiltersAndFetch,
  } = usePagination(getAllMonthlyReports)

  const tableData = useMemo(() => {
    const formattedTable = formatDataForTable(data, columnsToDisplay)
    return {
      ...formattedTable,
      data: formatDataDateByKey(formattedTable.data, 'created_at'),
    }
  }, [data])

  const renderActionsColumn = useCallback(
    form => (
      <div className="flex gap-3 justify-center">
        <Tooltip content="Download Monthly Report">
          <span className="cursor-pointer">
            <a href={`${form.url}`} target="_blank" rel="noopener noreferrer">
              <CloudArrowDown className="h-4 w-4" />
            </a>
          </span>
        </Tooltip>
      </div>
    ),
    [],
  )

  const handleTypeChange = selectedOption => {
    setSelectedType(
      selectedOption?.value === selectedType ? '' : selectedOption?.value,
    )
  }

  const handleUploadClick = () => {
    setIsModalOpen(true)
  }

  const handleFileChange = event => {
    setCsvFile(event.target.files[0])
  }

  const handleUploadSubmit = () => {
    handleUploadReport(
      selectedType,
      csvFile,
      setIsUploading,
      handleFilter,
      showToast,
    )
    setCsvFile(null)
    setSelectedType(null)
    setIsModalOpen(false)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
    setCsvFile(null)
    setSelectedType(null)
  }

  const filtersConfig = {
    showFilterDropdown: true,
    showDateRangeFilter: true,
    showSearch: false,
  }

  const filterProps = {
    dateRange,
    selectedFilter,
    onFilterChange: handleFilterChange,
    onDateRangeSubmit: handleDateRangeSubmit,
    filterOptions: filterSubmissionOptions,
  }

  const handleFilterApplyFromDrawer = () => {
    setIsFilterDrawerOpen(false)
    const updatedFilters = {
      ...filters,
      ['filter']: selectedFilter,
    }
    setFilters(updatedFilters)
    updateFiltersAndFetch(null, updatedFilters, dateRange)
  }

  const handlePartnerFilterChange = selectedOption => {
    const selectedValue = selectedOption?.value || ''
    const updatedFilters = {
      ...filters,
      ['partner']: filters['partner'] === selectedValue ? '' : selectedValue,
    }
    setFilters(updatedFilters)
    updateFiltersAndFetch(null, updatedFilters, dateRange)
  }

  return (
    <>
      {isLoading || isUploading ? (
        <div className="flex justify-center py-24">
          <Spinner color="default" size="lg" />
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <div className="flex justify-start w-full gap-4">
            <Button
              className="py-3 min-w-40 items-center text-xs bg-gray-950 text-white"
              radius="sm"
              variant="solid"
              startContent={<Upload />}
              onClick={handleUploadClick}
            >
              Upload CSV
            </Button>
          </div>
          <div className="flex items-center justify-between py-4">
            <div className="basis-8/12">
              <h2 className="text-2xl text-gray-600">Monthly Reports</h2>
            </div>
            <div className="basis-1/6">
              <FilterDropdown
                label="Filter by Partner"
                options={partnerList}
                value={filters.partner}
                onChange={selectedOption =>
                  handlePartnerFilterChange(selectedOption)
                }
                buttonClassName="text-nowrap "
              />
            </div>

            <div className="basis-1/6">
              <Tooltip content="Open Filters">
                <Button
                  auto
                  type="button"
                  onPress={() => setIsFilterDrawerOpen(true)}
                  className="bg-transparent border-none p-0 h-6 w-6"
                >
                  <Funnel size={32} />
                </Button>
              </Tooltip>
              {isFilterDrawerOpen && (
                <FiltersSideDrawer
                  isOpen={isFilterDrawerOpen}
                  onClose={() => setIsFilterDrawerOpen(false)}
                  filtersConfig={filtersConfig}
                  filterProps={filterProps}
                  onApplyFilters={handleFilterApplyFromDrawer}
                  handleClearFilters={resetFilters}
                />
              )}
            </div>
          </div>

          <DefaultTableComponent
            columns={tableData.columns}
            data={tableData.data}
            renderActions={renderActionsColumn}
            columnsExclude={['ID', 'URL']}
          />
        </div>
      )}

      <PaginationComponent
        total={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        className="p-4 mt-3 flex flex-col items-end gap-x-2"
      />
      <InformationModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        title="Upload CSV File"
        body={
          <div className="flex flex-col gap-4">
            <Input
              type="file"
              onChange={handleFileChange}
              accept=".csv"
              className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:bg-gray-50 file:text-gray-700 hover:file:bg-gray-100"
            />

            <div className="basis-1/6">
              <FilterDropdown
                options={typeSubmissionOptions}
                value={selectedType}
                onChange={handleTypeChange}
                label="Type"
                Icon={Article}
              />
            </div>
          </div>
        }
        footerActions={uploadReportModalActions(handleUploadSubmit).map(
          action =>
            action.label === 'Upload'
              ? { ...action, disabled: !csvFile }
              : action,
        )}
      />
    </>
  )
}
