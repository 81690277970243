import { createSlice } from '@reduxjs/toolkit'

export const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState: {
    currentUser: {},
    isLoggedIn: false,
  },
  reducers: {
    setCurrentUser(state, action) {
      state.currentUser = action.payload
      state.isLoggedIn = true
    },
    clearCurrentUser(state) {
      state.currentUser = {}
      state.isLoggedIn = false
      localStorage.removeItem('accessToken')
    },
    updateCurrentUser(state, action) {
      state.currentUser = {
        ...state.currentUser,
        ...action.payload,
      }
    },
    refreshCurrentUser(state, action) {
      state.currentUser = action.payload
      state.isLoggedIn = true
    },
  },
})

export const {
  setCurrentUser,
  clearCurrentUser,
  updateCurrentUser,
  refreshCurrentUser,
} = currentUserSlice.actions
export const selectCurrentUser = state => state.currentUser
export const selectIsLoggedIn = state => state.currentUser.isLoggedIn
export const currentUserReducer = currentUserSlice.reducer
