import { Button } from '@heroui/react'
import { useFilters } from 'hooks/useFilters'
import { usePagination } from 'hooks/usePagination'
import { Spinner, Tooltip } from '@nextui-org/react'
import { getAllReports } from 'services/partnerService'
import React, { useCallback, useMemo, useState } from 'react'
import { formatDataDateByKey, formatDataForTable } from 'helpers'
import { FiltersSideDrawer } from 'components/generics/filters-drawer'
import { usePartnersListFromRedux } from 'hooks/usePartnersListFromRedux'
import { CloudArrowDown, ArrowsClockwise, Funnel } from '@phosphor-icons/react'
import { FilterDropdown } from 'components/generics/filter-dropdown/FilterDropDown'
import { PaginationComponent } from 'components/generics/pagination/PaginationComponent'
import { DefaultTableComponent } from 'components/generics/default-table/DefaultTableComponent'
import {
  columnsToDisplay,
  filterSubmissionOptions,
} from './report-downoad-tab-constant'

export const PartnerReportsDownloadTab = () => {
  const partnerList = usePartnersListFromRedux()
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false)
  const [filters, setFilters] = useState({ filter: '', partner: '' })

  const {
    data,
    totalPages,
    currentPage,
    isLoading,
    handlePageChange,
    updateFiltersAndFetch,
  } = usePagination(getAllReports)

  const {
    selectedFilter,
    dateRange,
    handleFilterChange,
    handleDateRangeSubmit,
    resetFilters,
  } = useFilters({
    enableSearch: false,
    enableFilter: true,
    enableDateRange: true,
  })

  const tableData = useMemo(() => {
    const formattedTable = formatDataForTable(data, columnsToDisplay)
    return {
      ...formattedTable,
      data: formatDataDateByKey(formattedTable.data, 'created_at'),
    }
  }, [data])

  const renderActionsColumn = useCallback(
    form => (
      <div className="flex gap-3 justify-center">
        {form?.status === 'ready' ? (
          <Tooltip content="Download">
            <span className="cursor-pointer">
              <a href={`${form.url}`} target="_blank" rel="noopener noreferrer">
                <CloudArrowDown className="h-4 w-4" />
              </a>
            </span>
          </Tooltip>
        ) : (
          <Tooltip content="Pending, Click to Refresh Page">
            <button
              onClick={() => window.location.reload()}
              className="cursor-pointer flex items-center"
            >
              <ArrowsClockwise className="h-4 w-4" />
            </button>
          </Tooltip>
        )}
      </div>
    ),
    [],
  )

  const filtersConfig = {
    showFilterDropdown: true,
    showDateRangeFilter: true,
    showSearch: false,
  }

  const filterProps = {
    dateRange,
    selectedFilter,
    onFilterChange: handleFilterChange,
    onDateRangeSubmit: handleDateRangeSubmit,
    filterOptions: filterSubmissionOptions,
  }

  const handleFilterApplyFromDrawer = () => {
    setIsFilterDrawerOpen(false)
    const updatedFilters = {
      ...filters,
      ['filter']: selectedFilter,
    }
    setFilters(updatedFilters)
    updateFiltersAndFetch(null, updatedFilters, dateRange)
  }

  const handlePartnerFilterChange = selectedOption => {
    const selectedValue = selectedOption?.value || ''
    const updatedFilters = {
      ...filters,
      ['partner']: filters['partner'] === selectedValue ? '' : selectedValue,
    }
    setFilters(updatedFilters)
    updateFiltersAndFetch(null, updatedFilters, dateRange)
  }

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center py-24">
          <Spinner color="default" size="lg" />
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <div className="flex items-center justify-between">
            <div className="basis-8/12">
              <h2 className="text-2xl text-gray-600">Reports Data</h2>
            </div>
            <div className="basis-1/6">
              <FilterDropdown
                label="Filter by Partner"
                options={partnerList}
                value={filters.partner}
                onChange={selectedOption =>
                  handlePartnerFilterChange(selectedOption)
                }
                buttonClassName="text-nowrap "
              />
            </div>

            <div className="basis-1/6">
              <Tooltip content="Open Filters">
                <Button
                  auto
                  type="button"
                  onPress={() => setIsFilterDrawerOpen(true)}
                  className="bg-transparent border-none p-0 h-6 w-6"
                >
                  <Funnel size={32} />
                </Button>
              </Tooltip>
              {isFilterDrawerOpen && (
                <FiltersSideDrawer
                  isOpen={isFilterDrawerOpen}
                  onClose={() => setIsFilterDrawerOpen(false)}
                  filtersConfig={filtersConfig}
                  filterProps={filterProps}
                  onApplyFilters={handleFilterApplyFromDrawer}
                  handleClearFilters={resetFilters}
                />
              )}
            </div>
          </div>

          <DefaultTableComponent
            columns={tableData.columns}
            data={tableData.data}
            renderActions={renderActionsColumn}
            columnsExclude={['ID', 'URL']}
          />
        </div>
      )}

      <PaginationComponent
        total={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        className="p-4 mt-3 flex flex-col items-end gap-x-2"
      />
    </>
  )
}
