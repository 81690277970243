import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { HomePage } from 'pages/homepage/HomePage'
import { TestingEnvBanner } from 'TestingEnvBanner'
import ProtectedRoutes from 'routes/ProtectedRoutes'
import { EditClaimTemplatePage } from 'pages/admin/edit-template'
import { ClaimFormPage } from 'pages/partner/claim-form/ClaimFormPage'
import { UnauthorizePage } from 'pages/unauthorize-page/UnauthorizePage'
import { PasswordReset } from 'components/authentication/reset-password'
import { AdminDashboardPage } from 'pages/admin/dashboard/AdminDashboardPage'
import { CustomToastContainer } from 'components/generics/toast/ToastContainer'
import { ClaimFormsViewTab } from 'components/partner/dashboard/claim-forms-tab'
import { ParkGuardCustomersData } from 'components/admin/dashboard/customers-data'
import { PartnerDashboardPage } from 'pages/partner/dashboard/PartnerDashboardPage'
import { MonthlyReportsTab } from 'components/partner/dashboard/monthly-reports-tab'
import { ClaimSubmissionThankYou } from 'components/generics/claim-submission-thank-you'
import { ClaimSubmissionsTab } from 'components/partner/dashboard/claim-submissions-tab'
import { PartnerReportsDownloadTab } from 'components/partner/dashboard/reports-download-tab'
import { ParkGuardClaimsSubmissionData } from 'components/admin/dashboard/claims-submission-data'
import { ClaimFormSingleView } from 'components/partner/dashboard/claim-submissions-tab/claim-form-view'
import { CustomerDetailsView } from 'components/partner/dashboard/customers-info-tab/customer-detail-view'
import { EditClaimSubmissionData } from 'components/admin/dashboard/claims-submission-data/edit-claim-data'

function App() {
  return (
    <>
      <TestingEnvBanner />
      <Routes>
        {/* Public Routes */}
        <Route exact path="/" element={<HomePage />} />
        <Route path="/unauthorized" element={<UnauthorizePage />} />
        <Route path="/claim-thank-you" element={<ClaimSubmissionThankYou />} />
        <Route path="/:url_suffix" element={<ClaimFormPage />} />

        {/* Protected Routes */}
        <Route
          element={<ProtectedRoutes allowedRoles={['parkguard_employee']} />}
        >
          <Route path="/admin/dashboard" element={<AdminDashboardPage />}>
            <Route
              path="edit-claim-template/:url_suffix"
              element={<EditClaimTemplatePage />}
            />
            <Route
              path="parkguard-customers-data"
              element={<ParkGuardCustomersData />}
            />
            <Route
              path="parkguard-claims-submission-data"
              element={<ParkGuardClaimsSubmissionData />}
            />
          </Route>
          <Route
            path="edit-claim-form/:claim_id"
            element={<EditClaimSubmissionData />}
          />
        </Route>

        <Route
          element={<ProtectedRoutes allowedRoles={['partner_employee']} />}
        >
          <Route path="/partner/dashboard" element={<PartnerDashboardPage />}>
            <Route path="claim-forms" element={<ClaimFormsViewTab />} />
            <Route path="claim-submissions" element={<ClaimSubmissionsTab />} />
            <Route
              path="reports-download"
              element={<PartnerReportsDownloadTab />}
            />
            <Route path="monthly-reports" element={<MonthlyReportsTab />} />
            <Route path="reset-password" element={<PasswordReset />} />
          </Route>
        </Route>

        {/* Shared Protected Routes */}
        <Route
          element={
            <ProtectedRoutes
              allowedRoles={['partner_employee', 'parkguard_employee']}
            />
          }
        >
          <Route
            path="view-customer-details/:customer_id"
            element={<CustomerDetailsView />}
          />
          <Route
            path="view-claim/:claim_id"
            element={<ClaimFormSingleView />}
          />
        </Route>
      </Routes>
      <CustomToastContainer />
    </>
  )
}

export default App
