import { useSelector } from 'react-redux'
import { formatPartnerList } from 'helpers'

export const usePartnersListFromRedux = () => {
  const { currentUser } = useSelector(state => state.currentUser)

  if (!currentUser) return []

  const partners = [
    {
      id: currentUser.partner.id,
      name: currentUser.partner.name,
    },
    ...(currentUser.childrens?.map(child => ({
      id: child.id,
      name: child.name,
    })) || []),
  ]
  return formatPartnerList(partners)
}
